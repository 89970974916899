import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useAuthContext } from '../../../auth/useAuthContext';
import { CustomAvatar } from '../../../components/custom-avatar';
// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));
// ----------------------------------------------------------------------
export default function NavAccount() {
    const { user } = useAuthContext();
    const username = [user?.firstName, ' ', user?.lastName].join('');
    return (_jsxs(StyledRoot, { children: [_jsx(CustomAvatar, { src: user?.photo, alt: user?.firstName, name: user?.firstName }), _jsxs(Box, { sx: { ml: 2, minWidth: 0 }, children: [_jsx(Typography, { variant: "subtitle2", noWrap: true, children: username }), _jsx(Typography, { variant: "body2", noWrap: true, sx: { color: 'text.secondary' }, children: user?.role?.code })] })] }));
}
